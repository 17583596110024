@import '@monorepo/base/src/styles/text/overflow';

.wrapper {
	.inputWrapper {
		margin: 0px 0px 10px 0px;
	}
	.list {
		overflow-y: auto;
		max-height: 185px;
		.listItem {
			padding: 10px 5px 7px 12px;
			margin: 0px 0px 3px 0px;
			font-size: 14px;
			display: flex;
			justify-content: space-between;

			&:hover,
			&.selected {
				background-color: #f2f2f2;
			}

			.label {
				@include text-overflow;
				padding-right: 5px;
			}
			.checkIcon {
			}
		}

		.separatorLine {
			margin: 10px 0px;
		}
	}

	.empty {
		padding: 10px;
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
		display: flex;
		flex-direction: column;
		gap: 5px;

		span {
			text-align: center;
			text-wrap: wrap;
		}

		span:nth-child(2) {
			font-size: 12px;
		}
	}

	.multipleInfoAndActions {
		display: flex;
		justify-content: space-between;
		padding: 10px 0 10px;
		font-size: 12px;

		.limit {
			display: flex;
			padding: 5px 10px 5px 10px;
		}

		.multipleActions {
			display: flex;
			gap: 15px;

			.selectAll {
				padding: 5px 10px 5px 10px;
				cursor: pointer;
				color: #5552ee;
				transition: background-color 0.2s ease-in-out;
				border-radius: 8px;

				&:hover {
					background-color: #f3f4f6;
				}
			}
			.clearAll {
				padding: 5px 10px 5px 10px;
				cursor: pointer;
				color: #5552ee;
				transition: background-color 0.2s ease-in-out;
				border-radius: 8px;

				&:hover {
					background-color: #f3f4f6;
				}
			}
		}
	}
}
