@import './quick-actions-in-cell.default';
@import 'src/styles/theme';

.quickActionsInCell {
	max-width: 100px;
	display: flex;
}

.action {
	background-color: transparent;
	border: none;
	padding: 0 0 0 6px;

	span:first-child {
		padding: 5px;

		&:hover {
			border-radius: 50%;
			color: $primaryColor;
			background-color: $primaryColorLight;
		}
	}
}
