@import 'src/styles/theme';

.wrapper {
	display: flex;
	align-items: center;
	.list {
		max-height: 335px !important;
	}
}

.breadcrumbWrapper {
	display: flex;
	align-items: center;
	position: relative;

	.skeletons {
		width: 140px;
		height: 50px;

		.topSkeleton {
			width: 75px;
		}

		.bottomSkeleton {
			width: 150px;
		}
	}

	.next {
		font-size: 25px;
		font-weight: 100;
		margin: 0px 15px;
		color: #dadce0;
		position: absolute;
		right: 0;
	}
}

.active {
	color: $primary600;
}

.emptyState {
	width: 300px;
	top: calc(100% + 5px) !important;
}

.actAs {
	display: flex;
	height: 50px;
	padding-top: 6px;
	position: relative;

	.actAsUserInfo {
		display: flex;
		flex-direction: column;

		.text {
			font-size: 11px;
			color: $secondaryColor;
		}

		.id {
			padding-top: 3px;
			font-size: 14.5px;
			font-weight: 500;
			color: $secondaryColor;
		}
	}
}

//maybe delete and implement .next later
.actAsDivider {
	font-size: 25px;
	font-weight: 100;
	margin: 0px 15px;
	color: #dadce0;
	margin: 0px 15px;
}

.actAsIcon {
	position: relative;
	top: -3px;
	margin: 0px 15px 0  0;
	font-size: 30px;
	color: $secondaryColor;
}
