@import './error-msg.default.scss';

@keyframes opacityIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.error {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: $errorMsgGap;
	min-width: $errorMsgMinWidth;
	max-width: $errorMsgMaxWidth;
	height: $errorMsgHeight;
	font-size: $errorMsgFontSize;
	padding: $errorMsgPadding;
	line-height: $errorMsgLineHeight;
	color: $errorMsgColor;
	border-radius: $errorMsgBorderRadius;
	animation: opacityIn 0.2s ease-in forwards;
}

.listItemTimer {
	display: flex;
	flex-direction: row;
}
