.countries {
    display: flex;
    align-items: center;
    gap: 7px;

    img {
        width: 18px;
        position: relative;
        top: -1px;
    }

    span {
        line-height: 18px;
    }
}

.centeredCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;
}