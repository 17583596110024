@import '@monorepo/base/src/styles/devices';
@import 'src/styles/theme';

.wrapper {
	display: flex;
	flex-direction: column;
	padding: 24px;

	.date {
		color: $primary600;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}

	.paragraphsWrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;

		.paragraph {
			display: flex;
			flex-direction: column;
			gap: 4px;

			.heading {
				font-weight: 600;
				font-size: 36px;
				line-height: 44px;
				letter-spacing: -0.02em;
				color: $gray900;
			}

			.subHeading {
				font-weight: 600;
				font-size: 28px;
				line-height: 44px;
				letter-spacing: -0.02em;
				color: $gray900;
			}

			.content {
				color: $gray600;
				font-weight: 400;
				font-size: 18px;
				line-height: 28px;
			}
		}
	}
}

.underTenOrderedList {
	padding-inline-start: 24px;
}

.orangeText {
	color: #ff8c00;
}
