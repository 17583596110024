@import 'src/styles/theme';

$navBarHeight: 50px;

.navBar {
	min-height: $navBarHeight;
	padding: 0px 23px 0px 20px;
	border-bottom: 1px solid #e7eaf0;
	background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	z-index: 11;
	top: 0px;
	left: 0px;
	width: -webkit-fill-available;

	&.actAs {
		background-color: #f0f8ff;
	}

	.begin {
		min-height: $navBarHeight;
		height: $navBarHeight;
		display: flex;
		align-items: center;
		gap: 80px;
	}
	.end {
		display: flex;
		gap: 0;

		.divider {
			font-size: 19px;
			border-left: $subTitleColor 1px solid;
			max-height: 25px;
			min-height: 25px;
			align-self: center;
		}
	}
}
