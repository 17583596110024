@import './roles-selector.default.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 18px;

	.title {
	}

	.selectionWrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	.addAnotherRoleWrapper {
		padding-top: 10px;
		display: flex;
		cursor: pointer;
		color: $rolesSelectorAddAnotherRoleWrapperColor;

		&.disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}
}
