@import 'src/styles/theme';

.title {
	color: $titleColor;
	font-size: 24px;
	font-weight: bold;
}

.errorMsg {
	min-width: 100px;
}

.wrapper {
	top: 30px;
	position: absolute;
}

.userBottomBarWrapper {
	gap: 12px;
	padding: 0;
	position: relative;
}

.userBottomBar {
	border: none !important;
	border-top: 1px solid #eaecf0 !important;
	padding: 10px !important;
}

.userSiderSlider {
	width: 700px;
	min-width: 700px;
	padding: 21px 24px;
}
