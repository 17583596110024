code {
	background-color: #f5f5f5;
	border-radius: 3px;
	font-family: "Roboto Mono", monospace;
	font-size: 14px;
	padding: 2px 4px;
}

link {
	color: #1a0dab;
}

.tableStyle {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	border: 1px solid #ddd;

	td,
	th {
		border: 1px solid #ddd;
	}
}