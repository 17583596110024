@import '@monorepo/base/src/styles/devices';
@import 'src/styles/theme';

.signinContent {
	display: flex;
	align-items: right;
	justify-content: center;
	flex-direction: column;
	// height: 100%;
	width: 100%;
	max-width: 350px;
	position: relative;

	.title {
		font-size: 32px;
		font-weight: 600;
		margin-bottom: 10px;
		width: 100%;
	}

	.subtitle {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: $gray600;
		margin-bottom: 25px;
		text-align: left;
	}

	.lineSeperator {
		width: 100%;
		text-align: center;
		border-bottom: 1px solid #667085;
		line-height: 0.1em;
		margin: 30px 0 30px;
		color: #667085;
		font-weight: 400;

		.lineText {
			background: #fff;
			padding: 0 10px;
			font-size: 13px;
		}
	}

	.formWrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;

		.inputWrapper {
			display: flex;
			flex-direction: column;
			gap: 4px;

			.formText {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 20px;
				color: $gray700;
			}

			.formInput {
				width: 100%;

				.passwordVisibilityIcon {
					font-size: 25px;
					color: $gray500;
					-webkit-user-select: none;
					/* Safari */
					-ms-user-select: none;
					/* IE 10 and IE 11 */
					user-select: none;
					/* Standard syntax */

					&:hover {
						background-color: transparent;
					}
				}
			}
		}

		.formOptions {
			display: flex;
			position: relative;
			flex-direction: row;
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 20px;
			width: 100%;
			align-content: space-between;
			margin: auto;

			// .rememberMe {
			// 	display: flex;
			// 	justify-content: left;
			// 	text-align: left;
			// 	flex: 1;
			// 	gap: 5px;
			// 	flex-direction: row;
			// }

			.forgotPassword {
				justify-content: right;
				// flex: 1; uncomment to move it to the right
				text-align: right;
				color: $primary700;
				font-weight: 600;
			}
		}

		.termsAndPrivacy {
			font-size: 13px;

			.terms,
			.policy {
				color: $primary700;
				font-weight: 600;
			}
		}

		.signInBtn {
			margin: 4px 0 8px 0;
		}

		.error {
			justify-content: flex-start !important;
			margin-top: -30px;
			font-size: 13px;
			padding-left: 5px;
		}

		.signUpWrapper {
			display: flex;
			flex-direction: row;
			align-self: center;
			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 18px;

			.signUp {
				color: $primary700;
				font-weight: 600;
			}

			.signUp:hover {
				cursor: pointer;
			}
		}
	}
}

@media (max-width: $largerDesktop) {
	.signinContent {
		max-width: 350px;
		position: relative;

		.title {
			font-size: 30px;
			margin-bottom: 10px;
			width: 100%;
		}

		.subtitle {
			font-size: 14px;
			line-height: 18px;
			margin-bottom: 18px;
		}

		.lineSeperator {
			line-height: 0.1em;
			margin: 23px 0 23px;

			.lineText {
				padding: 0 10px;
				font-size: 13px;
			}
		}

		.formWrapper {
			gap: 20px;

			.inputWrapper {
				gap: 4px;

				.formText {
					font-size: 13px;
					line-height: 20px;
				}

				.formInput {
					.passwordVisibilityIcon {
						&:hover {
						}
					}
				}
			}

			.formOptions {
				font-size: 13px;
				line-height: 20px;

				.rememberMe {
					gap: 5px;
				}

				.forgotPassword {
				}
			}

			.signInBtn {
				margin: 4px 0 8px 0;
			}

			.signUpWrapper {
				font-size: 13px;
				line-height: 18px;

				.signUp {
					color: $primary700;
					font-weight: 600;
				}

				.signUp:hover {
				}
			}
		}
	}
}
