@import 'src/styles/theme';
@import './table-filters.default.scss';
@import '@monorepo/base/src/styles/keyframes/slide-in-up';
@import '../action-liner/action-liner.default.scss';

.wrapper {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	.filtersWrapper {
		height: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		gap: $tableFiltersFiltersWrapperGap;

		.filters {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: $tableFiltersFiltersGap;
			row-gap: $tableFiltersFiltersRowGap;
			.filterWrapper {
				height: $actionLinerHeight;
				align-items: center;
				display: flex;

				.filterComponentMenu {
					top: $tableFiltersFilterComponentMenuFromTop;
					width: 300px;
				}

				.filter {
					padding: $tableFiltersFilterPadding;
					line-height: $tableFiltersFilterLineHeight;
					background-color: $tableFiltersFilterBackgroundColor;
					border-radius: $tableFiltersFilterBorderRadius;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: $tableFiltersFilterFontSize;
					cursor: pointer;
					border: $tableFiltersFilterBorder;
					color: $tableFiltersFilterColor;
					font-weight: $tableFiltersFilterFontWeight;
					white-space: pre;
					transition: 0.2s ease-in-out;

					&:hover {
						background-color: $tableFiltersFilterHoverBackgroundColor;
					}

					.labelCircle {
						padding: $tableFiltersLabelCirclePadding;
						margin: $tableFiltersLabelCircleMargin;
						width: $tableFiltersLabelCircleWidth;
						height: 100%;
						font-weight: $tableFiltersLabelCircleFontWeight;
						color: $tableFiltersLabelCircleColor;
						background-color: $tableFiltersLabelCircleBackgroundColor;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
					}

					.filterContent {
						display: flex;
						align-items: center;
						gap: $tableFiltersFilterContentGap;

						span {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							max-width: $tableFiltersFilterContentMaxWidth;
						}
					}

					.clearIcon {
						margin: $tableFiltersClearIconMargin;
					}
				}
			}
		}

		.menus {
			height: 100%;

			.menu {
				top: $tableFiltersMenuFromTop;
				padding: $tableFiltersMenuPadding;
				overflow: visible;
				width: 296px;

				.nav {
					max-height: 350px;
					overflow: auto;
				}

				.menuItem {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
				}
			}
		}
	}

	.actions,
	.actions .contentActions {
		height: $actionLinerHeight;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
