@import 'src/styles/theme';

.formWrapper {
	height: 88vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.error {
		justify-content: flex-start !important;
		margin-top: -23px;
		text-align: center;
		font-size: 13px;
		padding-left: 5px;
		position: relative;
		top: 13px;
		color: $error500
	}

	.otpWrapper {
		width: 290px;
		display: flex;
		flex-direction: column;
		gap: 13px;

		.resend2Fa {
			font-size: 13px;
			display: flex;
			flex-direction: column;
			justify-self: center;
			align-items: center;

			.cta {
				font-weight: 600;
				cursor: pointer;
				color: #5552ee;

				&.disabled {
					color: $gray400;
					cursor: not-allowed;
				}
			}
			.timerWrapper {
				position: relative;

				.compTimerWrapper {
					position: absolute;
					top: 5px;
					left: 50%;
					transform: translate(-50%, 0%);
				}
			}
		}
	}
}
