@import 'src/styles/theme';

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	max-width: 360px;
	margin: 96px auto 0;
	.iconWrapper {
		height: 56px;
		width: 56px;
		text-align: center;
		background-color: $primary100;
		border-radius: 28px;
		border: 10px solid $primary50;
		display: flex;
		justify-content: center;

		&.success {
			background-color: $success100;
			border: 10px solid $success50;
		}
	}

	.description {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.primary {
			font-size: 30px;
			font-weight: 600;
			line-height: 38px;
			letter-spacing: 0em;
			text-align: center;
		}
		.secondary {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0em;
			text-align: center;
			display: flex;
			flex-direction: column;

			.sentTo {
				font-weight: 600;
				color: $gray600;
			}
		}
	}

	.resendEmail {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: center;

		.link {
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: 0em;
			text-align: center;
			color: $primaryColor;
			cursor: pointer;
		}
	}

	.inputInfoOverwrite {
		margin-left: -55px;
	}

	.passwordVisibilityIcon {
		font-size: 25px;
		color: $gray500;
		-webkit-user-select: none;
		/* Safari */
		-ms-user-select: none;
		/* IE 10 and IE 11 */
		user-select: none;
		/* Standard syntax */

		&:hover {
			background-color: transparent;
		}
	}

	.passwordErrWrapper {
		display: flex;
		flex-direction: column;
		align-self: flex-start;

		.passwordErr {
			display: flex;
			gap: 8px;
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
			color: $gray500;

			.checkIcon {
				color: #5fd788;
				font-size: 17px;
				font-weight: 700;
			}

			.xIcon {
				font-size: 17px;
				font-weight: 700;
				color: #e86062;
			}
		}
	}

	.passwordInput,
	.confirmPasswordInput {
		width: 362px;
	}

	.resetBtn {
		width: 360px;
	}
	.goToLoginBtn {
		width: 360px;
	}

	.backWrapper {
		display: flex;
		gap: 8px;
		cursor: pointer;

		.backText {
			font-weight: 600;
			line-height: 20px;
			color: $gray600;
			font-size: 14px;
		}
	}
}
