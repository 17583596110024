@import 'src/styles/theme';

.wrapper {
	margin-top: 96px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;

	.iconWrapper {
		height: 56px;
		width: 56px;
		text-align: center;
		background-color: $primary100;
		border-radius: 28px;
		border: 10px solid $primary50;
		display: flex;
		justify-content: center;
	}

	.description {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.primary {
			font-size: 30px;
			font-weight: 600;
			line-height: 38px;
			letter-spacing: 0em;
			text-align: center;
		}
		.secondary {
			font-size: 16px;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 0em;
			text-align: center;
			display: flex;
			flex-direction: column;

			.sentTo {
				font-weight: 600;
				color: $gray600;
			}
		}
	}

	.resendEmail {
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
		display: flex;
		flex-direction: column;
		align-items: center;

		.link {
			font-size: 14px;
			font-weight: 600;
			line-height: 20px;
			letter-spacing: 0em;
			text-align: center;
			color: $primaryColor;
			cursor: pointer;
			display: flex;

			&.disabled {
				color: $gray400;
				cursor: default;
			}
		}

		.countdown {
			display: flex;
			color: $gray400;
			font-weight: 400;
		}
	}

	.emailInput {
		width: 362px;
	}

	.resetBtn {
		width: 360px;
	}

	.backWrapper {
		display: flex;
		gap: 8px;
		cursor: pointer;

		.backText {
			font-weight: 600;
			line-height: 20px;
			color: $gray600;
			font-size: 14px;
		}
	}
}
