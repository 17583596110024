@import './input.default.scss';
@import '@monorepo/base/src/styles/devices';

.wrapper,
.unstyledWrapper {
	&.wrapper {
		position: relative;
		margin: $inputWrapperMargin;
		max-height: $inputWrapperMaxHeight;
	}

	.topLabel {
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 20px;
		color: #344054;
		margin-bottom: 4px;
	}

	.bottomLabel {
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		font-size: 12px;
		color: #667085;
		margin-top: 4px;
	}

	.label {
		position: absolute;
		top: $inputLabelTop;
		left: $inputLabelLeft;
		font-size: $inputLabelFontSize;
		background-color: $inputLabelBackgroundColor;
		padding: $inputLabelPadding;
		box-sizing: border-box;
		color: $inputLabelColor;
		opacity: $inputLabelOpacity;
		transition: opacity 0.2s ease-in-out;
		outline: none;
	}

	.inputWrapper {
		display: flex;
		align-items: center;
		position: relative;
		box-shadow: $inputBoxShadow;
		border: $inputBorder;
		color: $inputColor;
		height: $inputHeight;
		border-radius: $inputBorderRadius;
		max-width: $inputWrapperMaxWidth;
		outline: none;

		&.disabled {
			background-color: #fafafa;
			cursor: not-allowed;
		}

		&.focused {
			outline: none;
			border: $inputFocusBorder;
			box-shadow: $inputFocusBoxShadow;
		}

		&.inline {
			border: none;
			border-bottom: $inputBorder;
			box-shadow: none;
			border-color: #e6e6e6;
			border-radius: 0px;

			&.focused {
				border: none;
				border-bottom: $inputBorder;
				box-shadow: none;
			}
		}

		.icon {
			position: absolute;
			left: $inputWrapperIconFromLeft;
			color: $inputWrapperIconColor;
		}

		.inputWithIcon {
			padding: $inputWithIconPadding !important;
		}

		.before {
			color: $inputPrefixColor;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0px 10px 0px 10px;

			&.borderBefore {
				border-right: $inputBorder;
				background-color: #f5f5f5;
				border-top-left-radius: $inputBorderRadius;
				border-bottom-left-radius: $inputBorderRadius;
			}
		}

		.after {
			color: $inputPrefixColor;
			padding: 0px 10px 0px 10px;

			&.borderAfter {
				border-left: $inputBorder;
			}
		}

		&.inputError {
			border: $inputErrorBorder;
		}

		.input {
			width: 100%;
			height: 100%;
			max-width: $inputMaxWidth;
			min-width: $inputMinWidth;
			box-shadow: none;
			border: none;
			color: $inputColor;
			appearance: none;
			border-radius: $inputBorderRadius;
			padding: $inputPadding;
			font-size: $inputFontSize;
			box-sizing: border-box;
			outline: none;
			transition: 0.2s ease-in-out;
			font-weight: $inputFontWeight;
			line-height: $inputLineHeight;
			font-variant: none;

			&.hide {
				flex: 1 1 0;
				padding: 0;
			}

			&:last-child {
				margin-bottom: 0px !important;
			}

			&::placeholder {
				font-size: $inputPlaceholderFontSize;
			}

			&:focus {
				&.inputWithPrefix {
					border: transparent !important;

					&:focus {
						box-shadow: none !important;
						border-top-left-radius: 0px !important;
						border-bottom-left-radius: 0px !important;
						border: transparent !important;
					}
				}
			}

			&:focus,
			&.notEmpty {
				&::placeholder {
					opacity: $inputPlaceholderFocusOpacity;
				}

				~ .label {
					opacity: $inputPlaceholderFocusLabelOpacity;
				}
			}

			&.disableAnimate {
				&::placeholder {
					opacity: 1;
				}
				~ .label {
					opacity: 0;
				}
			}

			&.textarea {
				max-height: max-content !important;
				max-width: 100% !important;
				height: 100% !important;
			}

			&.inputTouched {
				border: $inputErrorTouched;
			}

			&.extraMaxWidth {
				max-width: $inputExtraMaxWidth;
			}
		}

		.displayedNumberValue {
			padding: 16px;
			display: flex;
			align-items: center;
			width: 100%;
			flex: 1 1 1;

			&.removePadding {
				padding: 0;
			}
		}
	}
}

.textareaWrapper {
	height: auto !important;
}

@media (max-width: $largeDesktop) {
	.wrapper,
	.unstyledWrapper {
		&.wrapper {
			margin: $inputWrapperMargin;
			max-height: $inputWrapperMaxHeight;
		}

		.topLabel {
			font-size: 13px;
			line-height: 20px;
			margin-bottom: 4px;
		}

		.bottomLabel {
			line-height: 20px;
			font-size: 12px;
			margin-top: 4px;
		}

		.label {
			top: -12px;
			left: 10px;
			font-size: 12px;
			padding: 0 6px;
		}

		.inputWrapper {
			color: $inputColor;
			// height: 30px; // here
			max-width: $inputWrapperMaxWidth;

			&.focused {
			}

			&.inline {
				&.focused {
				}
			}

			.icon {
				left: $inputWrapperIconFromLeft;
			}

			.inputWithIcon {
				padding: $inputWithIconPadding !important;
			}

			.before {
				padding: 0px 10px 0px 10px;

				&.borderBefore {
				}
			}

			.after {
				padding: 0px 10px 0px 10px;

				&.borderAfter {
				}
			}

			&.inputError {
			}

			.inputAndSpan {
				.input {
					max-width: $inputMaxWidth;
					min-width: $inputMinWidth;
					border-radius: $inputBorderRadius;
					padding: 12px !important;
					font-size: $inputFontSize;
					font-weight: $inputFontWeight;
					line-height: $inputLineHeight;

					&.disabled {
						background-color: #f5f5f5;
						cursor: not-allowed;
					}

					&.hide {
						flex: 1 1 0;
						padding: 0;
					}

					&:last-child {
						margin-bottom: 0px !important;
					}

					&::placeholder {
						font-size: $inputPlaceholderFontSize;
					}

					&:focus {
						&.inputWithPrefix {
							&:focus {
							}
						}
					}

					&:focus,
					&.notEmpty {
						&::placeholder {
						}

						+ .label {
						}
					}

					&.disableAnimate {
						&::placeholder {
						}
						+ .label {
						}
					}

					&.textarea {
					}

					&.inputTouched {
					}

					&.extraMaxWidth {
						max-width: $inputExtraMaxWidth;
					}
				}

				.displayedNumberValue {
					padding: 16px;
					width: 100%;
				}
			}
		}

		.textareaWrapper {
			height: auto !important;
		}
	}
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
	color: transparent !important;
	transition: none !important;
	width: 0;

	&:focus {
		width: 100%;
		color: $inputColor !important;
	}
}
