@import 'src/styles/theme.scss';

.wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 24px;
	margin-bottom: 24px;

	.input {
		width: 100%;
	}
}
