@import '@monorepo/base/src/styles/devices';
@import 'src/styles/theme';

.wrapper {
	display: flex;
	width: 100%;

	&.sticky {
		height: 100vh;
		// overflow: hidden;
	}

	.outletWrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		position: relative;

		.manifestoLogo {
			position: absolute;
			left: 32px;
			top: 32px;
		}

		.card {
			display: flex;
			flex-direction: column;
			gap: 40px;
			width: 360px;
			// height: 100%;
			position: relative;
			top: 35%;
			left: 50%;
			transform: translate(-50%, -25%);
		}

		.credits {
			color: #475467;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			left: 32px;
			bottom: 25px;
			position: absolute;
		}
	}

	.rightSection {
		width: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		min-height: 100vh;
		background-image: url('../../../../assets/publifesto-bg-right.png');
		background-repeat: no-repeat, repeat;
		background-size: cover;

		@media (max-width: $largeTablet) {
			display: none;
		}

		.text {
			padding-left: 91px;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;

			.purpleText {
				position: relative;
				z-index: 3;
				color: #5552ee;
				font-size: 48px;
				font-weight: 900;
				line-height: 53px;
				margin-bottom: 15px;
			}

			.blackText {
				position: relative;
				z-index: 3;
				font-size: 90px;
				font-weight: 900;
				line-height: 99px;
				width: 700px;
			}

			.circle {
				border-radius: 50%;
				position: absolute;
				z-index: 1;

				&.orange {
					background-color: #f64b11;

					&.small {
						bottom: 450px;
						left: 790px;
					}

					&.large {
						width: 183px;
						height: 183px;
						bottom: -108px;
						left: 20px;
					}
				}

				&.purple {
					background: #5552ee;

					&.small {
						bottom: 17px;
						left: 690px;
					}

					&.medium {
						bottom: 360px;
						left: 690px;
					}
				}

				&.small {
					width: 25px;
					height: 25px;
				}

				&.medium {
					width: 96px;
					height: 96px;
				}
			}
		}
	}
}

@media (max-width: $largerDesktop) {
	.wrapper {
		.rightSection {
			.text {
				.purpleText {
				}
				.blackText {
				}
				.circle {
					&.orange {
						&.small {
							left: 740px;
						}

						&.large {
						}
					}

					&.purple {
						&.small {
						}

						&.medium {
							left: 640px;
						}
					}

					&.small {
					}

					&.medium {
					}
				}
			}
		}
	}
}
