@import 'src/styles/theme.scss';

.formWrapper {
	width: 50%;
	display: flex;
	flex-direction: column;
	color: black !important;
	padding-bottom: 75px;
	max-width: 730px;

	.bottomBar {
		width: auto;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 3;
		border-top: 1px solid #e7eaf0;

		.button {
			width: 100%;
			height: 50px;
			border-radius: 4px;
			background-color: #344054;
			color: white;
			font-weight: 500;
			font-size: 13px;
			line-height: 20px;
		}
	}

	.buttonGroup {
		margin-bottom: 25px;
		margin-top: 30px;

		.title {
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 20px;
		}
	}

	.gbutton {
		font-weight: 500;
		font-size: 13px !important;
		line-height: 20px !important;
	}

	.disclaimer {
		padding-top: 15px;
		padding-bottom: 15px;
		font-size: 14px;

		a {
			color: blue;
			text-decoration: underline;
		}
	}

	h3 {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 40px;
	}

	.wire {
		margin-top: 20px;
		.payeeInformation {
			margin-bottom: 20px;
			margin-top: 60px;
		}

		.bankInformation {
			margin-top: 20px;
		}
	}

	.paypal {
		margin-top: 20px;
	}
	.row {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 10%;
		width: 100%;
	}

	.input,
	.dropdown {
		width: 100%;
		height: 80px;
		max-width: 330px;
	}

	#middleDivider {
		margin-top: 50px;
		margin-bottom: 30px;
	}

	.textareaRow {
		margin-top: 30px;
		margin-bottom: 30px;

		font-weight: 500;
		font-size: 13px !important;
		line-height: 20px !important;
		width: 100%;

		.textarea {
			.textareaLabel {
				opacity: unset !important;
				margin: -10px -17px;

				font-weight: 500;
				font-size: 13px !important;
				line-height: 20px !important;
				color: #344054 !important;
			}
		}
	}

	.input {
		margin-bottom: 10px;
		margin-top: 10px;
	}

	#lastInput {
		width: 45%;
	}

	.dropdown {
		margin-top: 10px;
		max-width: 355px;
	}

	// 	label {
	// 		margin: -10px -17px;
	// 		color: #344054 !important;
	// 		opacity: unset !important;

	// 		font-weight: 500;
	// 		font-size: 13px !important;
	// 		line-height: 20px !important;
	// 	}
	// }
}
