.wrapper {
	display: flex;
	gap: 24px;
	justify-content: flex-start;
	min-width: 650px;

	.rolesDropdown,
	.entityDropdown {
		min-width: 300px;
		max-width: 300px;
		height: 44px;
	}

	.removeIcon {
		position: relative;
		left: -8px;
		cursor: pointer;

		&.disabled {
			cursor: not-allowed;
			opacity: 0.5;

			&:hover {
				background-color: transparent;
			}
		}
	}
}
